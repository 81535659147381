.company {
  .content__item:nth-child(odd) {
    .content__detail {
      .indicator {
        border-left: 2px solid #363636;
        margin-left: 26px;
      }
    }
  }
  .content__item:nth-child(even) {
    text-align: right;
    .content__detail {
      .indicator {
        border-right: 2px solid #363636;
        margin-right: 26px;
      }
    }
  }
  .content__item {
    padding-bottom: 80px;
    .content__head {
      color: $black;
      font-size: 40px;
      font-family: Crimson Text;
      padding-bottom: 30px;
    }
    .content__detail {
      div {
        @include flex(1);
        font-family: Muli;
      }
      .indicator {
        font-size: 15px;
        padding: 29px;
        line-height: 1.6;
        text-align: justify;
        white-space: pre-line;
      }
      div.half,
      div.full {
        .text {
          font-size: 15px;
          line-height: 1.6;
          margin-top: 55px;
          margin-right: 55px;
          text-align: justify;
          white-space: pre-line;
          letter-spacing: -0.1px;
        }
      }
      .history__table {
        background-color: #fefefe;
        font-family: Crimson Text;
        overflow-y: scroll;
        height: 470px;
        table {
          height: 100%;
          min-height: 459px;
        }
        th {
          width: 115px;
          text-align: center;
          font-size: 24px;
          line-height: 1;
          letter-spacing: -0.6px;
          color: $black;
        }
        td {
          padding-top: 24px;
          padding-bottom: 18px;
          padding-left: 14px;
          padding-right: 30px;
          border-left: #eeeeee 1px solid;
          font-size: 14px;
        }
        /*tbody tr:first-child {
          th,
          td {
            border-bottom: #eeeeee 1px solid;
          }
        }*/
        tbody tr {
          height: 100%;
          /* min-height: 324px; */
          vertical-align: text-bottom;
          th,
          td {
            border-bottom: #eeeeee 1px solid;
          }
          td p {
            font-style: italic;
          }
        }
        tbody tr:nth-child(3) {
          th,
          td {
            border-bottom: 0px;
          }
        }
        /*tbody tr:nth-child(2) {
          height: 100%;
          min-height: 324px;
          vertical-align: text-bottom;
        }*/
      }
    }
  }
  @media (max-width: $breakWidth) {
    .content__item:nth-child(odd) {
      .content__detail {
        .indicator {
          border-left: 1px solid #363636;
          margin-left: 6px;
          padding-right: 0;
        }
      }
    }
    .content__item:nth-child(even) {
      text-align: right;
      .content__detail {
        .indicator {
          border-right: 1px solid #363636;
          margin-right: 6px;
          padding-left: 0;
        }
      }
    }
    .content__item {
      .content__detail {
        .indicator {
          padding: 6%;
        }
        .history__table {
          margin-top: 64px;
        }
        .history__table p {
          font-size: 14px;
        }
        .history__table td {
          padding-top: 24px;
          padding-right: 8px;
          font-size: 14px;
        }
        div.half,
        div.full {
          .text {
            font-size: 16px;
            line-height: 1.5;
            margin-right: 12px;
            text-align: justify;
          }
        }
      }
      .content__head {
        font-size: 36px;
      }
    }
  }
}
