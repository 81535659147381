.link {
  cursor: pointer;
}
.fill {
  min-height: 100%;
  height: 100%;
}
.vh-100 {
  min-height: 100vh;
}
blockquote {
  margin-left: 40px;
}
.dashboard__content {
  margin-top: 8%;
  td {
    vertical-align: middle;
    .x370y300 {
      width: 370px;
      height: 300px;
    }
  }
}

.admin-notice {
  .title {
    font-weight: bold;
    font-size: 1.4rem;
    border-bottom: 2px solid black;
    padding: 0 0 1.4rem 0.5rem;
  }

  .thisMon {
    &:hover {
      font-weight: bold;
    }
  }
  .value {
    color: #232323;
    padding: 0 0 0 0.5rem;
    font-family: NotoSansCJKkr;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content-value {
    padding: 0 0 11px 0.5rem;
  }
  .input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 0 1rem;
    input {
      width: 90%;
      height: 38px;
      padding: 0.4rem;
      border: 1px solid #ced4da;
      border-radius: 5px;
      -webkit-box-shadow: 0;
      box-shadow: 0;
      outline: 0;
    }

    .date-box-pre {
      width: 90%;
      height: 38px;
      padding: 0.4rem;
      border-radius: 5px;
      outline: 0;
      position: relative;
      border: 1px solid #ced4da;
      .date-box {
        height: 38px;
        padding: 0.4rem;
        border-radius: 5px;
        outline: 0;
        position: relative;
        img {
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
    }
    .input-box {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 1000000;
    }
  }
  .editor-box {
    padding: 0 1rem;
  }

  .notice-submit-btn {
    background-color: #007bff;
    color: white;
    padding: 0.7rem 2rem;
    margin: 0 1rem 0.4rem 0;
    border-radius: 3px;
  }
  .delete {
    background-color: #dc3545;
    border: 0;
  }
  .content {
    height: 196px;
    border-radius: 6px;
    border: solid 1px #ced4da;
  }

  .preview-box {
    width: 30%;
    position: relative;
    .preview-image {
      width: 100%;
    }
    .reset {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  .dayPicker {
    width: 300px;
    border: 1px solid #eeeeee;
    position: absolute;
    background-color: white;
  }
  .calendar {
    width: 300px;
    display: flex;
    justify-content: space-around;

    align-items: center;
    flex-wrap: wrap;
    .dateButton {
      display: block;
      text-align: center;
      width: 10px;
      padding: 0.5rem 1.9rem 0.5rem 0.7rem;
      font-size: 1rem;
    }
  }
  .header {
    font-size: 1.25rem;
    padding: 0.75em 0.75em 0 0.75em;
  }
  .header {
    display: flex;
    p {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;
    }
    span {
      cursor: pointer;
    }
  }

  .headings {
    display: flex;
    padding: 0 1rem 0 1rem;
    font-size: 1.25em;
    justify-content: space-between;
    .dayLabel {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  .header > span > button {
    border: none;
    margin: 0 1rem;
    padding: 0.25rem 0.5rem;
  }

  .calendar > * {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.notice-board {
  width: 80%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;

  .new-notice-btn {
    background-color: #007bff;
    font-family: NotoSansCJKkr;
    font-size: 16px;
    color: white;
    padding: 0.7rem 2rem;
    margin-bottom: 0.4rem;
  }
  .notice-dashboard {
    width: 100%;
    -ms-flex-item-align: start;
    align-self: flex-start;
    .head {
      border-top: 1px solid #232323;
      border-bottom: 1px solid #dedede;
      padding: 0.5rem 1rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .none {
        opacity: 0;
      }
      span {
        text-align: center;
        font-size: 15px;
      }
    }
    .item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border-bottom: 1px solid #dedede;
      padding: 0.8rem 0;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
      }
      .notice-date {
        text-align: center;
        font-family: Muli;
        color: #6f6f6f;
        font-size: 15px;
      }
      .notice-title {
        font-family: NotoSansCJKkr;
        font-size: 15px;
        padding-left: 15px;
      }
    }
  }
  .notice-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 2rem 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    div {
      font-family: Muli;
      font-size: 14px;
      color: #9e9e9e;
      margin: 0 1rem;
      cursor: pointer;
    }

    .active {
      color: black;
      font-weight: bold;
    }
    span {
      width: 25px;
      // height: 25px;
      margin: 0 3.5rem;
      -ms-flex-item-align: start;
      align-self: flex-start;
    }
    .fa-w-10 {
      width: 8px;
      height: 12px;
    }
  }

  .disabled {
    color: #666666;
  }
  span {
    color: black;
    font-size: 1.1rem;

    cursor: pointer;
    a {
      padding: 1rem;
    }
  }
}

.editor {
  border: 2px solid #ced4da;
  margin: 1rem 0;
  p,
  a,
  img {
    &:focus {
      outline: none;
      border: none;
    }
    &:active {
      outline: none;
      border: none;
    }
  }
  .ql-editor {
    width: 100%;
    height: 500px !important;
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    background-color: #fafafa;
    img {
      width: 60%;
      max-height: 700px;
    }
  }
  .toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 2px solid #ced4da;
    div {
      img {
        display: inline-block;
        vertical-align: middle;
        padding: 0.5em 0.5em;
        cursor: pointer;
      }
    }
    label {
      display: inline-block;
      padding: 0.5em 0.75em;
      color: black;
      font-size: inherit;
      line-height: normal;
      vertical-align: middle;
      cursor: pointer;
      border-left: 1px solid #ced4da;
      margin: 0;
    }
    input[type="file"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}

@media (max-width: 500px) {
  .value {
    width: 100px;
  }
  .dayPicker {
    left: -60px;
    top: 30px;
  }
}

.notice-Letter {
  text-align: center;
  padding: 7rem 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}