.portfolio {
  .portfolio__desc {
    margin-top: 130px;
    padding: 56px 30px;
    font-family: Muli;
    font-size: 15px;
    line-height: 1.6;
    text-align: justify;
    color: #6f6f6f;
  }
  .portfolio_list {
    margin: 80px 0;
    background-color: #f6f6f6;
    padding: 12px 0 10px 12px;
    .portfolio_item:nth-child(odd) {
        // background-color: #6f6f6f;
    }
    .portfolio_item:nth-child(even) {
        // background-color: red;
    }
    .portfolio_item {
      width: 33%;
      height: 300px;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      // border: 1px solid #f6f6f6;
      img {
        // width: 100%;
      }
    }
  }
}
@media (max-width: $breakWidth) {
  .portfolio {
    .portfolio__desc {
      text-align: start;
      margin-top: 80px;
      padding: 31px 28px;
      font-size: 16px;
      line-height: 1.6;
      text-align: justify;
    }
    .portfolio_list {
      .portfolio_item {
        width: 100%;
      }
    }
  }
}
