.home {
  background-color: #4b4e50;
  background-image: url(assets/img/main.jpg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  @include flexbox();
  height: 100%;
  width: 100%;
  position: absolute;
  .title__warp,
  .portfolio__wrap {
    @include flexbox();
    @include flex(1);
    @include justify-content(center);
    @include align-items(flex-start);
    @include flex-direction(column);
    // width: 50%;
    margin-left: $homePaddingLeft;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  .title__warp {
    div {
      max-width: 532px;
      width: 100%;
      p {
        font-family: crimson text;
        font-size: 56px;
        font-weight: 700;
        line-height: 1;
        color: #ffffff;
        margin-bottom: 33px;
      }
      span {
        font-family: Muli;
        font-size: 15px;
        text-align: justify;
        line-height: 1.47;
        color: #ffffff;
        letter-spacing: -0.6px;
      }
    }
  }
  @media (max-width: $breakWidth) {
    .title__warp {
      margin-left: $homePaddingLeftMobile;
    }
    .title__warp div p {
      font-size: 56px;
      line-height: 1.07;
    }
    .title__warp div span {
      line-height: 1.6;
      text-align: justify;
    }
    .portfolio__wrap {
      display: none;
    }
  }
}
