.footer {
  height: 200px;
  background-color: $black;
  font-family: Muli;
  overflow: hidden;
  .footer__wrap {
    /* @include flexbox(); */
    display: block;
    padding: 40px 30px;
    height: 100%;
    .footer__main,
    .footer__sub {
      /* @include flex(1); */
    }
    .footer__main {
      font-size: 11px;
      font-weight: 300;
      line-height: 1.5;
      color: #ffffff;
      text-align: center;
      div:nth-child(1) {
        height: 44px;
      }
      div:nth-child(2) {
      }
    }
    .footer__sub {
      font-size: 10px;
      font-weight: 300;
      line-height: 1.6;
      text-align: justify;
      color: #6f6f6f;
    }
  }
}
@media (max-width: $breakWidth) {
  .footer {
    height: 100px;
  }
  .footer__sub {
    display: none;
  }
}
