.contact .contact__wrap {
  padding: 80px 30px;
  .desc {
    margin-top: 100px;
    padding: 19px 0;
    font-family: Crimson Text;
    font-size: 32px;
    line-height: 1.41;
    text-align: left;
    color: $black;
    white-space: pre-wrap;
  }
  .mailto {
    padding: 10px 0;
    font-family: Muli;
    font-size: 18px;
    color: #6f6f6f;
  }
  .tel {
    padding-top: 12px;
    font-family: Muli;
    font-size: 18px;
    color: #6f6f6f;
  }
  .fax {
    font-family: Muli;
    font-size: 18px;
    color: #6f6f6f;
  }
  .map {
    width: 100%;
    max-width: 1170px;
    height: 542px;
    margin-top: 150px;
    background-image: url(assets/img/map_w.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .notice {
    margin-top: 4rem;

    .notice-title {
      font-family: Crimson Text;
      font-size: 36px;
      line-height: 1.67;
      text-align: left;
      color: #232323;
    }
    .notice-search {
      position: relative;
      input {
        position: absolute;
        padding: 4px 25px 4px 4px;
        right: 0;
        border: solid 1px #232323;
      }
      div {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .notice-item-head {
      margin: 4rem auto 0 auto;
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #232323;
      border-bottom: 1px solid #dedede;
      .notice-item-date-box {
        padding-right: 20px;
      }
      .notice-item-index {
        text-align: start;
      }
      div {
        text-align: center;
        span {
          font-family: Muli;
          font-size: 15px;
          color: #232323;
        }
      }
    }
    .notice-items {
      width: 100%;
      margin: 0 auto;
      padding: 0.7rem 1.5rem;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 1px solid #eee;
      &:hover {
        background-color: #f5f5f5;
      }
      .notice-item-info {
        display: flex;
        align-items: center;
        .notice-item-title {
          padding-left: 10px;
        }
      }
      .notice-item-title {
        font-size: 15px;
        text-align: left;
        color: #232323;
      }
      .notice-item-date {
        font-family: Muli;
        font-size: 15px;
        text-align: center;
        color: #6f6f6f;
      }
      div {
        span {
          font-size: 15px;
        }
      }
    }
    .notice-Letter {
      text-align: center;
      padding: 7rem 0;

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .notice-detail-box {
    margin-top: 4rem;
    .notice-title {
      font-family: Crimson Text;
      font-size: 36px;
      line-height: 1.67;
      text-align: left;
      color: #232323;
      margin-bottom: 3rem;
    }
  }
  .notice-detail {
    .notice-detail-head {
      // border-bott  om: 1px solid #232323;

      padding-left: 1rem;
    }
    .notice-detail-title {
      font-family: NotoSansCJKkr;
      color: #232323;
    }
    .notice-detail-date {
      font-size: 15px;
      font-family: Muli;
      color: #6f6f6f;
    }
    .notice-detail-content {
      padding: 0 0 0 1rem;
      img {
        width: 100%;
      }
    }
    .notice-detail-bottom {
      padding: 2rem 0 0 1rem;
      // border-bottom: 1px solid #232323;
    }
    .notice-detail-nav {
      div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 1rem 0;
        cursor: pointer;
        span {
          font-family: NotoSansCJKkr;

          padding: 0 1rem;
          font-size: 16px;
        }
      }
      .notice-detail-nav-icon {
        margin-right: 1rem;
      }
    }
    .notice-detail-index {
      width: 100px;
      height: 30px;
      margin: 2rem 0 0 0;
      background-color: #232323;
      text-align: center;
      -ms-flex-line-pack: center;
      align-content: center;
      color: white;
      font-family: Muli;
    }
    .non-data {
      p,
      span {
        color: #9e9e9e;

        cursor: default;
      }
      svg{
        margin-right: 1rem;
      }
    }
  }
  .notice-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 2rem 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    div {
      font-family: Muli;
      font-size: 14px;
      color: #9e9e9e;
      margin: 0 1rem;
      cursor: pointer;
    }

    .active {
      color: black;
      font-weight: bold;
    }
    span {
      width: 25px;
      // height: 25px;
      margin: 0 3.5rem;
      -ms-flex-item-align: start;
      align-self: flex-start;
      text-align: center;
    }
    .fa-w-10 {
      width: 8px;
      height: 12px;
    }
  }

  .disabled {
    color: #666666;
  }
  span {
    color: black;
    font-size: 1.1rem;

    cursor: pointer;
    a {
      padding: 1rem;
    }
  }
}

@media (max-width: 500px) {
  .notice-search {
    form {
      input {
        width: 100%;
      }
    }
  }

  .first-item {
    margin-top: 1rem !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .notice-title {
    margin-bottom: 2rem;
  }

  .notice-item-head {
    border: 0 !important;
    margin: 0 !important;
    div {
      display: none;
    }
  }
  .notice-item-box {
    margin: 1rem 0;
    div {
      &:first-child {
        border-top: 1px solid #6f6f6f;
      }
    }
  }
  .notice-Letter {
    margin-top: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .notice-items {
    border-bottom: 1px solid #dedede;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .notice-item-info {
      .notice-item-title {
        padding: 0 !important;
      }
    }
    .notice-item-date {
      text-align: start !important;
    }
    div {
      width: 100%;
      .notice-item-id {
        display: none;
      }
      &:last-child {
        padding-top: 0.5rem;
        font-weight: 100;
        font-size: 0.8rem;
        color: #666666;
      }
      span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
  }
  .notice-detail {
    padding: 1rem 0 5rem 0 !important;
    .notice-detail-body {
      img {
        width: 100%;
      }
    }
    .notice-detail-nav {
      div {
        span {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media (max-width: $breakWidth) {
  .contact .contact__wrap {
    padding: 0 28px;
    .desc {
      font-size: 28px;
    }
    .map {
      margin: 130px 0 80px 0;
      height: 710px;
      background-image: url(assets/img/map_m.jpg);
      background-position: 15% 10%;
    }
  }
}
