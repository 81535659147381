.nav {
  position: absolute;
  width: 100%;
  height: $navHeight;
  z-index: 1;
  @include flexbox();
  .logo__warp,
  .menu__warp {
    @include flex(1);
    margin-top: $paddingTop;
    .menu__button {
      width: 54px;
      height: 54px;
      background-image: url(assets/icon/baseline-menu-24px.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 24px;
    }
  }
  .logo__warp {
    /* margin-left: $paddingSide; */
    margin-left: 57px;
    margin-top: 27px;
    background-image: url(assets/img/logo.png);
    background-repeat: no-repeat;
    background-size: 170px 54px;
    width: 170px;
    height: 54px;
    cursor: pointer;
  }
  .menu__warp {
    @include flexbox();
    @include justify-content(flex-end);
    padding-right: $paddingSide;
  }
  @media (max-width: $breakWidth) {
    .logo__warp {
      margin-left: $paddingSideMoblie;
      margin-top: 35px;
      background-size: 137px 43px;
      width: 137px;
      height: 43px;
    }
    .menu__warp {
      padding-right: $paddingSideMoblie;
    }
  }
}

.nav.open {
  .sidenav-overlay {
    display: block;
  }
  .side__nav {
    transform: translateX(0px);
  }
}

.sidenav-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  will-change: opacity;
  .close__wrap {
    height: 100px;
    @include flexbox();
    @include justify-content(flex-end);
    padding-right: 30px;
    align-items: center;
    .close__button {
      width: 54px;
      height: 54px;
      background-image: url(assets/icon/baseline-close-24px.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 24px;
    }
  }
  .side__nav {
    position: fixed;
    max-width: 270px; /* 320 */
    width: 80%;
    right: 0;
    top: 0;
    margin: 0;
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    background-color: #fff;
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    backface-visibility: hidden;
    transform: translateX(105%);
    animation: mover 0.3s ease;
    // transition: all 3s ease;
    // transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    li {
      padding: 32px 0;
      height: 34px;
      font-size: 26px;
      font-weight: 700;
      font-family: 'Playfair Display';
      line-height: 0.75;
      text-align: left;
      margin: 0 30px;
      cursor: pointer;
      a {
        color: $black;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 4px;
      }
      a:hover {
        color: #000000;
        border-bottom: 1px solid $black;
      }
    }
    .locale__wrap {
      font-family: Raleway;
      font-size: 14px;
      span {
        padding-right: 16px;
        color: #6f6f6f;
        cursor: pointer;
      }
      span.active,
      span:hover {
        color: $black;
      }
    }
  }
}

@keyframes mover {
  0% {
    transform: translateX(105%);
  }
  100% {
    transform: translateX(0px);
  }
}
