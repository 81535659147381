.slider__container {
  width: 571px;
  overflow: hidden;
  padding: 0;
  position: relative;
  &.style {
    // opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  .slider__wrap {
    .slider__name {
      display: none;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
      font-size: 13px;
    }
    .items-container {
      display: table;
      table-layout: fixed;
      transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
      .item {
        display: table-cell;
        width: 100%;
        .item__content {
          width: 560px;
          .title {
            font-family: Crimson Text;
            font-size: 34px;
            line-height: 1.6;
            letter-spacing: -1px;
            text-align: left;
            padding-left: 50px;
            padding-right: 28px;
            padding-top: 46px; /* 22px before slider__warp display none */
            padding-bottom: 33px;
            white-space: pre-line;
          }
          .desc {
            font-family: Muli;
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: -0.5px;
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 81px;
            white-space: pre-line;
          }
        }
      }
    }
  }
  //pagination
  .pagination {
    position: absolute;
    bottom: 0;
    right: 0;
    li {
      background-color: #000000;
      width: 45px;
      height: 45px;
      display: table-cell;
      cursor: pointer;
      text-align: center;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 32px;
    }
    .prev {
      background-image: url(assets/icon/baseline-chevron_left-24px.svg);
    }
    .next {
      background-image: url(assets/icon/baseline-chevron_right-24px.svg);
    }
    .prev:hover,.next:hover {
      background-color: gray;
      filter: grayscale(100%);
    }
  }
}
