.layer__popup {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
  .head {
    text-align: right;
    background-color: $black;
  }
  img {
    width: 580px;
  }
}

.close__button {
  width: 40px;
  height: 40px;
  background-image: url(assets/icon/baseline-close-24px.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px;
}

@media (max-width: 640px) {
  .layer__popup {
    width: 100%;
    padding: 2%;
    top: 0;
    left: 0;
    img {
      width: 100%;
    }
  }
}
