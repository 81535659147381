@charset "utf-8";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // resize: none;
  outline: 0 !important;
}
input,
input:before,
input:after {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

ul,
ol,
dl,
menu,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}
li {
  list-style-type: none;
}
.no-js {
  #root {
    display: none;
  }
  .not-supported {
    text-align: center;
    width: 500px;
    margin: 150px auto;
  }
}
