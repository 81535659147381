.strategy {
  .content__list,.content__list__reverse {
    li:nth-child(odd) {
      .text {
        line-height: 1.6;
        margin-right: 170px;
      }
    }
    li:nth-child(even) {
      text-align: right;
      .text {
        text-align: start;
        /* margin-left: 170px; */
        margin-right: 0px !important;
      }
    }
  }
  .content__item {
    .content__head {
      color: $black;
      font-size: 40px;
      font-family: Crimson Text;
      padding-bottom: 30px;
    }
    .content__detail {

      div {
        @include flex(1);
        font-family: Muli;
      }
      .indicator {
        font-size: 15px;
        padding: 56px;
        line-height: 1.6;
      }
      div.half {
        .content__head {
          font-family: crimson Text;
          text-align: left;
          font-size: 28px;
        }
        .text {
          font-size: 15px;
          margin-top: 55px;
          line-height: 1.6;
          padding-right: 64px;
          padding-bottom: 62px;
          text-align: justify;
          letter-spacing: -0.1px;
          white-space: pre-line;
        }
      }
      div.full {
        .text {
          font-size: 15px;
          line-height: 1.6;
          margin-top: 55px;
          text-align: justify;
        }
      }
    }
  }
  @media (max-width: $breakWidth) {
    .content__list,.content__list__reverse {
      li:nth-child(odd) {
        .text {
          margin-right: 6% !important;
        }
      }
      li:nth-child(even) {
        .text {
          margin-left: 6% !important;
        }
      }
      .content__detail {
        div.half {
          margin-bottom: 70px;
          .text {
            margin-left: 0 !important;
          }
        }
        div.full {
          .text {
            font-size: 16px;
            line-height: 1.6;
          }
        }
      }
      .content__head {
        font-size: 36px;
        line-height: 1.22;
      }
    }
  }
}
