/**
 * layout
 */
// $breakWidth: 640px;
$breakWidth: 1024px;
$paddingTop: 23px;
$paddingSide: 42px;
$paddingSideMoblie: 18px;
$navHeight: 100px;
$homePaddingLeft: 66px;
$homePaddingLeftMobile: 30px;

$mastheadHeight: 560px;
$mastheadHeightMobile: 350px;

/**
 * color
 */
$gray: #4b4e50;
$descGray: #6f6f6f;
$black: $black;
