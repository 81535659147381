/* file */
.file_scroll {
  margin-bottom: 10px;
}

.form-group.file_form {
  position: static;
  margin-right: -23px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.form-group.file_form input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  border: 0;
  opacity: 0;
}

.form-group.file_form .btn_file {
  display: inline-block;
  width: 104px;
  height: 104px;
  vertical-align: middle;
  margin-bottom: 0;

  border: 1px solid #cdcdcd;
  cursor: pointer;
  background: url(./icon/plus-solid.svg) no-repeat center center #f6f6f6;
  -webkit-background-size: 15px auto;
  background-size: 15px auto;
}

.file_add_txt {
  display: block;
  font-size: 12px;
  color: #989898;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 5px;
}

.file_list {
  display: inline-block;
  vertical-align: middle;
}

.file_list li {
  display: inline-block;
  width: 106px;
  height: 106px;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
  border: 1px solid #cdcdcd;
}

.file_list > span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
}

.file_list li .file_del {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  right: 0;
  z-index: 5;
  background: url(./icon/times-solid.svg) no-repeat center center #e9ecef;
  -webkit-background-size: 11.5px auto;
  background-size: 11.5px auto;
}

.file_list .file_screen > span:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.file_list .file_area {
  display: block;
  width: 100%;
  height: 100%;
  background: url(./icon/file-regular.svg) no-repeat center 50% #fff;
  -webkit-background-size: 26px auto;
  background-size: 26px auto;
}

.file_list .file_screen .file_area {
  background: none;
}

.file_list .file_area img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.file_txt {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: left;
  padding: 8px;
  z-index: 3;
  color: #fff;
  strong {
    display: block;
    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-size: 10px;
  }
}
