.management {
  /* max-width: 753px !important; */
  // .profile:nth-child(even) {
  //   margin-left: auto;
  //   .profile__title {
  //     margin-left: auto;
  //   }
  // }

  .profile__list {
    li:nth-child(even) {
      background-color: #f6f6f6;
      .profile {
        margin-left: auto;
        .profile__title {
          margin-left: auto;
        }
      }
    }
    li:nth-last-child(1) {
      padding-bottom: 56px;
    }
  }

  .profile {
    max-width: 760px;
    padding-left: 28px;
    padding-right: 49px;
    padding-top: 130px;
    padding-bottom: 80px;
    font-family: Muli;
    color: $descGray;
    width: 100%;
    clear: both;
    .profile__title {
      display: table;
      margin: 30px 0;
      .position {
        min-width: 153px;
        display: table-cell;
        vertical-align: middle;
        white-space: pre-line;
      }
      .name {
        font-family: Crimson Text;
        font-size: 40px;
        color: $black;
        white-space: nowrap;
      }
    }
    .desc {
      margin: 56px 0;
      line-height: 1.6;
      text-align: justify;
      white-space: pre-line;
      font-size: 15px;
      letter-spacing: -0.1px;
    }
  }
}
@media (max-width: $breakWidth) {
  .management {
    .profile {
      padding-right: 28px;
      .profile__title {
        .position {
          min-width: 70px;
        }
        .name {
          font-size: 36px;
          padding-left: 19px;
        }
      }
      .desc {
        /* text-align: start; */
        text-align: justify;
        font-size: 15px;
      }
    }
  }
}
