.masthead {
  background-color: $gray;
  height: $mastheadHeight;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  .masthead__title {
    font-family: 'Playfair Display';
    font-size: 48px;
    font-weight: 900;
    line-height: 0.6;
    color: #ffffff;
    padding-bottom: 20px;
  }
  &.company {
    background-image: url(assets/img/bg-company.jpg);
  }
  &.management {
    background-image: url(assets/img/bg-management.jpg);
  }
  &.strategy {
    background-image: url(assets/img/bg-strategy.jpg);
  }
  &.portfolio {
    background-image: url(assets/img/bg-portfolio.jpg);
  }
  &.contact {
    background-image: url(assets/img/bg-contact.jpg);
  }
}
@media (max-width: $breakWidth) {
  .masthead {
    height: $mastheadHeightMobile;
  }
}
